import { HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { inject, makeStateKey, TransferState } from '@angular/core';

export const browserTranslationsInterceptor: HttpInterceptorFn = (req, next) => {
  if (req.method !== 'GET' || !req.url.includes('/i18n/')) {
    return next(req);
  }

  const transferState: TransferState = inject(TransferState);

  const storedResponse: string | undefined | null = transferState.get(makeStateKey(req.url), null);

  if (storedResponse) {
    const response = new HttpResponse({
      body: storedResponse,
      status: 200,
    });

    return of(response);
  }

  return next(req);
};
