import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { PreloadingStrategy, PRIMARY_OUTLET, Route, Router } from '@angular/router';
import { PrefetchRegistry } from './prefetch-registry.service';
import { EMPTY } from 'rxjs';
import { isPlatformServer } from '@angular/common';
import { injectNetwork } from 'ngxtension/inject-network';

@Injectable({ providedIn: 'root' })
export class QuicklinkStrategy implements PreloadingStrategy {
  loading = new Set<Route>();
  private readonly platformId: Object = inject(PLATFORM_ID);
  private readonly registry: PrefetchRegistry = inject(PrefetchRegistry);
  private readonly router: Router = inject(Router);
  private readonly network = injectNetwork();

  preload(route: Route, load: Function) {
    if (isPlatformServer(this.platformId)) {
      return EMPTY;
    }

    if (this.loading.has(route)) {
      // Don't preload the same route twice
      return EMPTY;
    }

    if (this.network.supported()) {
      if (this.network.saveData() || (this.network.effectiveType() || '').includes('2g')) {
        return EMPTY;
      }
    }

    // Prevent from preloading
    if (route.data && route.data['preload'] === false) {
      return EMPTY;
    }

    const fullPath = findPath(this.router.config, route);
    if (this.registry.shouldPrefetch(fullPath)) {
      this.loading.add(route);
      return load();
    }

    return EMPTY;
  }
}

const findPath = (config: Route[], route: Route): string => {
  config = config.slice();
  const parent = new Map<Route, Route>();
  const visited = new Set<Route>();
  while (config.length) {
    const el = config.shift();
    if (!el) {
      continue;
    }
    visited.add(el);
    if (el === route) break;
    let children = el.children || [];
    const current = (el as any)._loadedRoutes || [];
    for (const route of current) {
      if (route && route.children) {
        children = children.concat(route.children);
      }
    }

    children.forEach(r => {
      if (visited.has(r)) return;
      parent.set(r, el);
      config.push(r);
    });
  }
  let path = '';
  let current: Route | undefined = route;

  while (current) {
    if (isPrimaryRoute(current)) {
      path = `/${current.path}${path}`;
    } else {
      path = `/(${current.outlet}:${current.path}${path})`;
    }
    current = parent.get(current);
  }

  return path.replace(/\/\//, '/');
};

function isPrimaryRoute(route: Route) {
  return route.outlet === PRIMARY_OUTLET || !route.outlet;
}
