import { inject, Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IndexedDBPersistence implements PersistenceStorage {
  readonly storage: StorageMap = inject(StorageMap);

  public getItem(key: string): Observable<unknown> {
    return this.storage.get(key);
  }

  public setItem(key: string, value: unknown): Observable<undefined> {
    return this.storage.set(key, value);
  }

  public removeItem(key: string): Observable<undefined> {
    return this.storage.delete(key);
  }
}

/**
 * Represents the interface for store persistence methods.
 */
export interface PersistenceStorage {
  getItem(key: string): Observable<unknown>;

  setItem(key: string, value: unknown): Observable<undefined>;

  removeItem(key: string): Observable<undefined>;
}

/**
 * Loads a value from storage.
 *
 * @template TState - The type of state to load.
 * @returns The loaded value if available and successfully parsed, otherwise undefined.
 * @param persistenceStorage
 * @param persistenceKey
 */
export function loadFromStorage<TState>(
  persistenceStorage: PersistenceStorage,
  persistenceKey: string
): Observable<TState | undefined> {
  return persistenceStorage.getItem(persistenceKey).pipe(
    take(1),
    map(value => {
      try {
        return value ? (value as TState) : undefined;
      } catch {
        return undefined;
      }
    })
  );
}

/**
 * Saves a value to local storage.
 *
 * @template TState - The type of state to save.
 * @param persistenceStorage
 * @param persistenceKey
 * @param value - The store value to store.
 */
export function saveToStorage<TState>(
  persistenceStorage: PersistenceStorage,
  persistenceKey: string,
  value: TState
): void {
  persistenceStorage.setItem(persistenceKey, value).pipe(take(1)).subscribe();
}

/**
 * Clears the value associated with the provided key from local storage.
 *
 * @template TState - The type of state to clear from local storage.
 * @param persistenceStorage
 * @param persistenceKey
 */
export function clearStorage(persistenceStorage: PersistenceStorage, persistenceKey: string): void {
  persistenceStorage.removeItem(persistenceKey).pipe(take(1)).subscribe();
}
