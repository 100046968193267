import { effect, inject, isDevMode, makeStateKey, PLATFORM_ID, TransferState, untracked } from '@angular/core';
import { getState, patchState, signalStoreFeature, withHooks } from '@ngrx/signals';
import { isPlatformServer } from '@angular/common';
import { take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  IndexedDBPersistence,
  loadFromStorage,
  PersistenceStorage,
  saveToStorage,
} from '@owain/indexeddb/IndexedDB-persistence.service';

export function withIndexedDB<TState>(dbKey: string, blacklist?: string[]) {
  return signalStoreFeature(
    withHooks({
      onInit(store) {
        const platformId: Object = inject(PLATFORM_ID);

        if (isPlatformServer(platformId)) {
          return;
        }

        let restored = false;
        const indexedDBPersistence: IndexedDBPersistence = inject(IndexedDBPersistence);

        effect(() => {
          const state = getState(store);

          untracked(() => {
            if (restored) {
              saveToStoreStorage(indexedDBPersistence, dbKey, { ...state }, blacklist);
            }
          });
        });

        retrieveFromStoreStorage<TState>(indexedDBPersistence, dbKey, store).subscribe(() => {
          restored = true;
          if (isDevMode()) {
            console.log(dbKey, 'restored from indexed DB');
          }
        });
      },
    })
  );
}

function removeBlacklisted(value: any, blacklist?: string[]): any {
  if (!blacklist) {
    return value;
  }

  for (const prop in value) {
    if (value.hasOwnProperty(prop)) {
      for (const remove of blacklist) {
        if (prop.toLowerCase().includes(remove.toLowerCase())) {
          delete value[prop];
        }
      }
    }
  }

  return value;
}

function saveToStoreStorage(storage: PersistenceStorage, dbKey: string, value: any, blacklist?: string[]): void {
  saveToStorage(storage, dbKey, removeBlacklisted(value, blacklist));
}

function retrieveFromStoreStorage<TState>(storage: PersistenceStorage, dbKey: string, store: any): Observable<any> {
  return loadFromStorage<TState>(storage, dbKey).pipe(
    take(1),
    tap(persistedState => {
      if (persistedState) {
        patchState(store, persistedState);
      }
    })
  );
}

function hasTransferState(transferState: TransferState, key: string): boolean {
  const storedResponse: any | undefined | null = transferState.get(makeStateKey(key), null);
  return !!storedResponse;
}
