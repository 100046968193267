import { ApplicationConfig, isDevMode, provideExperimentalZonelessChangeDetection } from '@angular/core';
import {
  provideRouter,
  withComponentInputBinding,
  withDisabledInitialNavigation,
  withInMemoryScrolling,
  withPreloading,
  withViewTransitions,
} from '@angular/router';
import { APP_ROUTES } from './app.routes';
import { provideTransloco, TranslocoService } from '@jsverse/transloco';
import { provideTranslocoLocale } from '@jsverse/transloco-locale';

import { DOCUMENT, provideCloudflareLoader } from '@angular/common';
import { provideServiceWorker } from '@angular/service-worker';
import { provideNotifier } from '@owain/notifier/lib/notifier.module';
import { provideErrorTailorConfig } from '@ngneat/error-tailor';
import { DefaultFormErrorComponent } from './components/form-error/default-form-error.component';
import {
  provideIndexedDBDataBaseName,
  provideIndexedDBStoreName,
  provideLocalStoragePrefix,
} from '@ngx-pwa/local-storage';
import { environment } from '../environments/environment';
import { provideQuicklink } from '@owain/quicklink/quicklink.module';
import { QuicklinkStrategy } from '@owain/quicklink/quicklink-strategy.service';
import { provideRecaptchaV2V3Config } from '@owain/recaptcha/recaptcha-v3.module';
import { withLocalizeRouter } from '@owain/ngx-transloco-router/localize-router.module';
import { WINDOW, windowProvider } from '@owain/tokens/window.provider';
import { provideClientHydration, withEventReplay, withHttpTransferCacheOptions } from '@angular/platform-browser';

export const appConfig: ApplicationConfig = {
  providers: [
    provideExperimentalZonelessChangeDetection(),
    provideClientHydration(
      withEventReplay(),
      withHttpTransferCacheOptions({
        includePostRequests: true,
      })
    ),
    provideIndexedDBDataBaseName('archeryEventsDatabase'),
    provideIndexedDBStoreName('archeryEventsStore'),
    provideLocalStoragePrefix('archeryevents_'),
    provideQuicklink(),
    provideRouter(
      APP_ROUTES,
      withPreloading(QuicklinkStrategy),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
      }),
      withComponentInputBinding(),
      // withViewTransitions(),
      withLocalizeRouter(APP_ROUTES, {
        alwaysSetPrefix: true,
      })
    ),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideRecaptchaV2V3Config({
      v2SiteKey: environment.recaptchaV2,
      v3SiteKey: environment.recaptchaV3,
    }),
    provideCloudflareLoader('https://archery-events.com/'),
    provideTransloco({
      config: {
        availableLangs: ['en', 'nl'],
        defaultLang: 'en',
        fallbackLang: ['en', 'nl'],
        missingHandler: {
          useFallbackTranslation: true,
        },
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
        flatten: {
          aot: !isDevMode(),
        },
      },
    }),
    provideTranslocoLocale({
      localeConfig: {
        global: {
          date: {
            dateStyle: 'long',
            timeStyle: 'short',
          },
        },
      },
      langToLocaleMapping: {
        en: 'en-US',
        nl: 'nl-NL',
      },
    }),
    provideErrorTailorConfig({
      controlErrorComponent: DefaultFormErrorComponent,
      controlCustomClass: 'form-field-error',
      errors: {
        useFactory(service: TranslocoService) {
          return {
            required: () => service.translate('formerrors-required'),
            email: () => service.translate('formerrors-email'),
            passwordMismatch: () => service.translate('formerrors-passwordmismatch'),
            unavailableEmail: () => service.translate('formerrors-unavailableemail'),
            minlength: ({ requiredLength }) => service.translate('formerrors-minlength', { length: requiredLength }),
            maxlength: ({ requiredLength }) => service.translate('formerrors-maxlength', { length: requiredLength }),
          };
        },
        deps: [TranslocoService],
      },
    }),
    provideNotifier({
      theme: 'archery-events',
      behaviour: {
        autoHide: 2500,
      },
      position: {
        horizontal: {
          position: 'right',
          distance: 12,
        },
        vertical: {
          position: 'bottom',
          distance: 12,
          gap: 10,
        },
      },
    }),
    {
      provide: WINDOW,
      useFactory: (document: Document) => windowProvider(document),
      deps: [DOCUMENT],
    },
  ],
};
