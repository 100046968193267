@if (windowScrolled()) {
  <div class="scroll-to-top" @scrollToTop>
    <button
      (click)="scrollToTop()"
      class="h-7 w-7 bg-gray-200 dark:bg-slate-900 rounded text-gray-700 dark:text-gray-200">
      <div class="margins">
        <fast-svg name="icons/arrowup" size="20" class="margins" />
      </div>
      <span class="sr-only">Go to top</span>
    </button>
  </div>
}
