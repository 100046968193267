import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import type { IUser } from '@archery-events/models/user.model';
import { mergeWith, Observable, of } from 'rxjs';
import type { IMiscHttpError } from '@archery-events/models/misc.model';
import { toObservable } from '@angular/core/rxjs-interop';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { LocalizeRouterService } from '@owain/ngx-transloco-router/localize-router.service';
import { AdminStore } from '../../store/admin/admin.store';

export const adminUsersResolver = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
):
  | Observable<IUser[] | IMiscHttpError | null>
  | Promise<IUser[] | IMiscHttpError | null>
  | IUser[]
  | IMiscHttpError
  | null => {
  const adminStore: InstanceType<typeof AdminStore> = inject(AdminStore);

  if (adminStore.usersLoaded()) {
    return adminStore.userEntities();
  }

  const router: Router = inject(Router);
  const localizeRouterService: LocalizeRouterService = inject(LocalizeRouterService);

  const error$ = toObservable(adminStore.usersError).pipe(
    filter(err => err != null),
    tap(err => console.error(err)),
    tap(() => router.navigateByUrl(<string>localizeRouterService.translateRoute('/500'))),
    map(() => null)
  );

  const loaded$ = toObservable(adminStore.usersCallState).pipe(
    filter(callState => callState === 'loaded'),
    switchMap(() => of(adminStore.userEntities()))
  );
  adminStore.getUsers();

  return loaded$.pipe(mergeWith(error$));
};
