import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { browserConfig } from './app/app.config.browser';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';

function bootstrap() {
  if (environment.production) {
    enableProdMode();
  }

  bootstrapApplication(AppComponent, browserConfig).catch(err => console.log(err));
}

bootstrap();
