import { signalStore, withHooks, withMethods, withState } from '@ngrx/signals';
import type { ICookiePreferences } from '@archery-events/models/cookie-preferences.model';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  effect,
  inject,
  Injector,
  isDevMode,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2,
  runInInjectionContext,
} from '@angular/core';
import { withIndexedDB } from '@owain/store-features/features/web-storage/indexeddb.feature';
import { immerPatchState } from '@owain/store-features/state/immer.state';

export const initialState: ICookiePreferences = {
  saved: false,
  googleMaps: false,
  cloudflareInsights: false,
};

export const CookiePreferencesStore = signalStore(
  { providedIn: 'root' },
  // withLogger('CookiePreferencesStore'),
  withIndexedDB('cookie-preferences-store-state'),
  withState(initialState),
  withHooks({
    onInit(store) {
      const injector = inject(Injector);
      effect(() => {
        runInInjectionContext(injector, () => cloudflareChanged(store.cloudflareInsights()));
      });
    },
  }),
  withMethods(store => ({
    setSaved(saved: boolean): void {
      immerPatchState(store, state => {
        state.saved = saved;
      });
    },
    setGoogleMaps(googleMaps: boolean): void {
      immerPatchState(store, state => {
        state.googleMaps = googleMaps;
      });
    },
    setCloudflareInsights(cloudflareInsights: boolean): void {
      immerPatchState(store, state => {
        state.cloudflareInsights = cloudflareInsights;
      });
    },
  }))
);

function cloudflareChanged(payload: boolean) {
  const platformId: Object = inject(PLATFORM_ID);

  if (isDevMode() || !isPlatformBrowser(platformId)) {
    return;
  }

  const ngDocument: Document = inject(DOCUMENT);
  const rendererFactory: RendererFactory2 = inject(RendererFactory2);
  const renderer: Renderer2 = rendererFactory.createRenderer(null, null);

  const scripts = ngDocument.body.getElementsByTagName('script');
  if (payload) {
    if (scripts && scripts.length > 0) {
      for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].src.startsWith('https://static.cloudflareinsights.com')) {
          return;
        }
      }
    }

    const script = ngDocument.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://static.cloudflareinsights.com/beacon.min.js';
    script.defer = true;
    script.setAttribute('data-cf-beacon', '{"token": "6f5b4e7254664f44846a55e02103f480"}');
    renderer.appendChild(ngDocument.body, script);
  } else if (scripts && scripts.length > 0) {
    for (let i = 0; i < scripts.length; i++) {
      if (scripts[i].src.startsWith('https://static.cloudflareinsights.com')) {
        renderer.removeChild(ngDocument.body, scripts[i]);
        break;
      }
    }
  }
}
