import { NgModule } from '@angular/core';

import { ReCaptchaV3Service } from './recaptcha-v3.service';
import { RecaptchaLoaderService } from './recaptcha-loader.service';
import { RECAPTCHA_SETTINGS, RECAPTCHA_V3_SITE_KEY } from './tokens';
import { RecaptchaSettings } from './recaptcha-settings';

export interface RecaptchaV2V3ConfigOptions {
  v2SiteKey: string;
  v3SiteKey: string;
}

@NgModule({
  providers: [ReCaptchaV3Service, RecaptchaLoaderService],
})
export class RecaptchaV3Module {}

export function provideRecaptchaV2V3Config(recaptchaV2V3ConfigOptions: RecaptchaV2V3ConfigOptions) {
  return [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: recaptchaV2V3ConfigOptions.v2SiteKey,
      } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: recaptchaV2V3ConfigOptions.v3SiteKey,
    },
  ];
}
