import { APP_INITIALIZER, makeEnvironmentProviders, Provider } from '@angular/core';
import { FastSvgProviderOptions } from './provider-config.interface';
import { SvgOptionsToken } from './token/svg-options.token';
import { SvgOptions } from './token/svg-options.model';
import { SvgLoadStrategy } from './token/svg-load.strategy.model';
import { FASTSVG_PRELOAD_IMAGES, SvgRegistry } from './svg-registry.service';

/**
 * @description
 * Use this function to register the FastSvg providers in your application.
 *
 * @param options {FastSvgProviderOptions} - The options for the FastSvg providers.
 * @return { Provider[] } - The providers for the FastSvg module.
 *
 * @example
 *
 * ```ts
 * bootstrapApplication(AppComponent, {
 *  providers: [
 *   provideFastSVG({
 *     url: (name: string) => `path/to/svg-assets/${name}.svg`,
 *   })
 * ]});
 * ```
 */
export const provideFastSVG = (options: FastSvgProviderOptions): Provider[] => {
  const providers: Provider[] = [
    {
      provide: SvgOptionsToken,
      useValue: {
        url: options.url,
        suspenseSvgString: options.suspenseSvgString || undefined,
        defaultSize: options.defaultSize || undefined,
      } as SvgOptions,
    },
  ];

  if (options.svgLoadStrategy) {
    providers.push({ provide: SvgLoadStrategy, useClass: options.svgLoadStrategy } as Provider);
  }

  return providers;
};

export function provideFastSVGPreloadImages(images: string[]) {
  return makeEnvironmentProviders([
    { provide: FASTSVG_PRELOAD_IMAGES, useValue: images },
    {
      provide: APP_INITIALIZER,
      useFactory: (svgRegistry: SvgRegistry) => () => {
        svgRegistry.register();
      },
      multi: true,
      deps: [SvgRegistry],
    },
  ]);
}
