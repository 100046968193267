import { runInInjectionContext, inject, InjectionToken, ENVIRONMENT_INITIALIZER } from '@angular/core';
import { assertInjector } from 'ngxtension/assert-injector';
function createInjectFn(token) {
  return function ({
    injector,
    ...injectOptions
  } = {}) {
    injector = assertInjector(this, injector);
    return runInInjectionContext(injector, () => inject(token, injectOptions));
  };
}
function createProvideFn(token, factory, opts = {}) {
  const {
    deps = [],
    multi = false,
    extraProviders = [],
    isFunctionValue: isFunctionValueFromOpts = false
  } = opts;
  return (value, isFunctionValue = isFunctionValueFromOpts) => {
    let provider;
    if (typeof value !== 'undefined') {
      // TODO: (chau) maybe this can be made better
      const factory = typeof value === 'function' ? isFunctionValue ? () => value : value : () => value;
      provider = {
        provide: token,
        useFactory: factory,
        multi
      };
    } else {
      provider = {
        provide: token,
        useFactory: factory,
        deps: deps,
        multi
      };
    }
    return [extraProviders, provider];
  };
}
/**
 * `createInjectionToken` accepts a factory function and returns a tuple of `injectFn`, `provideFn`, and the `InjectionToken`
 * that the factory function is for.
 *
 * @param {Function} factory - Factory Function that returns the value for the `InjectionToken`
 * @param {CreateInjectionTokenOptions} options - object to control how the `InjectionToken` behaves
 * @returns {CreateInjectionTokenReturn}
 *
 * @example
 * ```ts
 * const [injectCounter, provideCounter, COUNTER] = createInjectionToken(() => signal(0));
 *
 * export class Counter {
 *  counter = injectCounter(); // WritableSignal<number>
 * }
 * ```
 */
function createInjectionToken(factory, options) {
  const tokenName = factory.name || factory.toString();
  const opts = options ?? {
    isRoot: true
  };
  opts.isRoot ??= true;
  // NOTE: multi tokens cannot be a root token. It has to be provided (provideFn needs to be invoked)
  // for the 'multi' flag to work properly
  if (opts.multi) {
    opts.isRoot = false;
  }
  if (opts.isRoot) {
    if (opts.token) {
      throw new Error(`\
createInjectionToken is creating a root InjectionToken but an external token is passed in.
`);
    }
    const token = new InjectionToken(`Token for ${tokenName}`, {
      factory: () => {
        if (opts.deps && Array.isArray(opts.deps)) {
          return factory(...opts.deps.map(dep => {
            dep = Array.isArray(dep) ? dep.at(-1) : dep;
            return inject(dep);
          }));
        }
        return factory();
      }
    });
    const injectFn = createInjectFn(token);
    return [injectFn, createProvideFn(token, factory, opts), token, () => ({
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => injectFn(),
      multi: true
    })];
  }
  const token = opts.token || new InjectionToken(`Token for ${tokenName}`);
  return [createInjectFn(token), createProvideFn(token, factory, opts), token, () => []];
}
function createNoopInjectionToken(description, options) {
  const token = options?.token || new InjectionToken(description);
  return [createInjectFn(token), createProvideFn(token, () => null, options || {}), token, () => []];
}

/**
 * Generated bundle index. Do not edit.
 */

export { createInjectionToken, createNoopInjectionToken };
