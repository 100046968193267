import {
  ApplicationRef,
  DestroyRef,
  inject,
  Injectable,
  isDevMode,
  PLATFORM_ID,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { first, tap } from 'rxjs/operators';
import { ApplicationUpdateComponent } from '../components/application-update/application-update.component';
import { concat, interval } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';
import { isPlatformBrowser } from '@angular/common';
import { ModalService } from './modal.service';
import { CookiePreferencesService } from './cookie-preferences.service';
import { WINDOW } from '@owain/tokens/window.provider';

@Injectable({
  providedIn: 'root',
})
export class SwUpdateService {
  private readonly applicationRef: ApplicationRef = inject(ApplicationRef);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly swUpdate: SwUpdate = inject(SwUpdate);
  private readonly window: Window = inject(WINDOW);
  private readonly platformId: Object = inject(PLATFORM_ID);
  private readonly cookiePreferencesService: CookiePreferencesService = inject(CookiePreferencesService);
  private readonly modalService: ModalService<ApplicationUpdateComponent> = inject(
    ModalService<ApplicationUpdateComponent>
  );

  public update(viewContainerRef: ViewContainerRef): void {
    this.swUpdate.versionUpdates
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(update => {
          if (update.type === 'VERSION_DETECTED') {
            console.info(`Downloading new app version: ${update.version.hash}`);
          } else if (update.type === 'VERSION_READY') {
            console.info(`Current app version: ${update.currentVersion.hash}`);
            console.info(`New app version ready for use: ${update.latestVersion.hash}`);

            this.cookiePreferencesService.close();

            this.modalService.viewContainerRef = viewContainerRef;
            this.modalService.open(ApplicationUpdateComponent);
          } else if (update.type === 'VERSION_INSTALLATION_FAILED') {
            console.error(`Failed to install app version '${update.version.hash}': ${update.error}`);
          }
        })
      )
      .subscribe();

    if (isPlatformBrowser(this.platformId)) {
      if (!isDevMode()) {
        concat(this.applicationRef.isStable.pipe(first(isStable => isStable)), interval(3 * 60 * 60 * 1000))
          .pipe(
            takeUntilDestroyed(this.destroyRef),
            tap(async () => {
              try {
                const updateFound = await this.swUpdate.checkForUpdate();
                if (updateFound) {
                  console.info('A new version is available.');

                  this.cookiePreferencesService.close();

                  this.modalService.viewContainerRef = viewContainerRef;
                  this.modalService.open(ApplicationUpdateComponent);
                }
              } catch (err) {
                console.error('Failed to check for updates:', err);
              }
            })
          )
          .subscribe();

        this.swUpdate.unrecoverable
          .pipe(
            takeUntilDestroyed(this.destroyRef),
            tap(() => {
              console.error('App is in unrecoverable state. Reloading to avoid chunk load issue.');
              this.window.location.reload();
            })
          )
          .subscribe();
      }
    }
  }
}
