<ul class="notifier__container-list">
  @for (notification of notifications; track notification.id) {
    <li class="notifier__container-list-item">
      <notifier-notification
        [notification]="notification"
        (ready)="onNotificationReady($event)"
        (dismiss)="onNotificationDismiss($event)" />
    </li>
  }
</ul>
