import { ApplicationRef, DestroyRef, inject, Injectable, ViewContainerRef } from '@angular/core';
import { ModalService } from './modal.service';
import { CookiesPopupComponent } from '../components/cookies/cookies-popup/cookies-popup.component';
import { first, switchMap, tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IndexedDBPersistence } from '@owain/indexeddb/IndexedDB-persistence.service';
import { WINDOW } from '@owain/tokens/window.provider';

@Injectable({
  providedIn: 'root',
})
export class CookiePreferencesService {
  private readonly window: Window = inject(WINDOW);
  private readonly applicationRef: ApplicationRef = inject(ApplicationRef);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly modalService: ModalService<CookiesPopupComponent> = inject(ModalService<CookiesPopupComponent>);
  private readonly indexedDBPersistence: IndexedDBPersistence = inject(IndexedDBPersistence);

  public checkPreferences(viewContainerRef: ViewContainerRef): void {
    this.applicationRef.isStable
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        first(isStable => isStable),
        switchMap(() => this.indexedDBPersistence.getItem('cookie-preferences-store-state')),
        tap((item: any) => {
          if (!item) {
            this.open(viewContainerRef);
            return;
          }

          try {
            if (!item.saved) {
              this.open(viewContainerRef);
            }
          } catch (e) {
            this.open(viewContainerRef);
          }
        })
      )
      .subscribe();
  }

  public open(viewContainerRef: ViewContainerRef): void {
    const detectRobot = (userAgent: string): boolean => {
      const robots = new RegExp(
        (
          [
            /bot/,
            /spider/,
            /crawl/, // GENERAL TERMS
            /APIs-Google/,
            /AdsBot/,
            /Googlebot/, // GOOGLE ROBOTS
            /mediapartners/,
            /Google Favicon/,
            /FeedFetcher/,
            /Google-Read-Aloud/,
            /DuplexWeb-Google/,
            /googleweblight/,
            /bing/,
            /yandex/,
            /baidu/,
            /duckduck/,
            /yahoo/, // OTHER ENGINES
            /ecosia/,
            /ia_archiver/,
            /facebook/,
            /instagram/,
            /pinterest/,
            /reddit/, // SOCIAL MEDIA
            /slack/,
            /twitter/,
            /whatsapp/,
            /youtube/,
            /semrush/, // OTHER
          ] as RegExp[]
        )
          .map(r => r.source)
          .join('|'),
        'i'
      ); // BUILD REGEXP + "i" FLAG

      return robots.test(userAgent);
    };

    if (!detectRobot(this.window.navigator.userAgent)) {
      this.modalService.viewContainerRef = viewContainerRef;
      this.modalService.open(CookiesPopupComponent);
    }
  }

  public close(): void {
    this.modalService.close();
  }
}
