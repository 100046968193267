import { APP_INITIALIZER, ApplicationConfig, isDevMode, mergeApplicationConfig } from '@angular/core';
import { appConfig } from './app.config';
import { BASE_URL, CAPTCHA_V3_SERVICE } from './tokens/injection.tokens';
import { TranslocoProductionHttpLoader } from './translate/transloco-production-loader';
import { TranslocoHttpLoader } from './translate/transloco-loader';
import { TRANSLOCO_LOADER } from '@jsverse/transloco';
import { provideHttpClient, withFetch, withInterceptors, withNoXsrfProtection } from '@angular/common/http';
import { jwtRefreshTokenInterceptor } from './interceptors/jwt-refresh-token.interceptor';
import { provideTranslocoPreloadLangs } from '@owain/transloco-preload/preload-langs.providers';
import { ReCaptchaV3Service } from '@owain/recaptcha/recaptcha-v3.service';
import { provideFastSVG, provideFastSVGPreloadImages } from '@owain/fast-svg/fast-svg.provider';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { browserTranslationsInterceptor } from './interceptors/browser-translations.interceptor';

const config: ApplicationConfig = {
  providers: [
    provideHttpClient(
      withFetch(),
      withNoXsrfProtection(),
      withInterceptors([
        // csrfInterceptor,
        jwtRefreshTokenInterceptor,
        browserTranslationsInterceptor,
      ])
    ),
    provideAnimationsAsync(),
    provideFastSVG({
      url: (name: string) => `assets/svg/${name}.svg`,
    }),
    provideTranslocoPreloadLangs([
      // Languages
      'en',
      'nl',

      'errorpages/en',
      'errorpages/nl',

      'profile/en',
      'profile/nl',

      'calendar/en',
      'calendar/nl',

      'redirect/en',
      'redirect/nl',

      'contact/en',
      'contact/nl',

      'signin/en',
      'signin/nl',

      'legal/en',
      'legal/nl',
    ]),
    provideFastSVGPreloadImages([
      'logo/archery-events',

      'icons/arrowup',
      'icons/check',
      'icons/checkcircle',
      'icons/chevron-down',
      'icons/chevron-up',
      'icons/code',
      'icons/cog',
      'icons/email',
      'icons/exclamationcircle',
      'icons/general',
      'icons/identification',
      'icons/infocircle',
      'icons/lightbulb',
      'icons/password',
      'icons/security',
      'icons/x',
      'icons/xcircle',
      'icons/edit',
      'icons/trash',
      'icons/chevron-left',
      'icons/chevron-right',
      'icons/chevron-double-left',
      'icons/chevron-double-right',
      'icons/register',
      'icons/settings',
      'icons/sign-in',
      'icons/sign-out',
      'icons/star',
      'icons/ellipsis-vertical',
      'icons/eye',
      'icons/eye-slash',
      'icons/globe',
      'icons/map-pin',
      'icons/chart-bar',
      'icons/home',

      'icons/menu-close',
      'icons/menu-hamburger',
      'icons/spinner',

      'flags/us',
      'flags/nl',

      'theme/moon',
      'theme/sun',
    ]),
    {
      provide: CAPTCHA_V3_SERVICE,
      useClass: ReCaptchaV3Service,
    },
    {
      provide: TRANSLOCO_LOADER,
      useClass: !isDevMode() ? TranslocoProductionHttpLoader : TranslocoHttpLoader,
    },
    {
      provide: BASE_URL,
      useValue: `${window.location.protocol}//${window.location.host}`,
    },
  ],
};

export const browserConfig = mergeApplicationConfig(appConfig, config);
