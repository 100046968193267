import { Routes } from '@angular/router';
import { profileMatchGuard } from './guards/profile/profile.guard';
import { adminMatchGuard } from './guards/admin/admin.guard';
import { adminUsersResolver } from './resolvers/admin/users.resolver';
import { userGuard } from './guards/user/user.guard';

export const APP_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/signin',
  },

  {
    path: 'contact',
    loadComponent: () => import('./pages/contact/contact.component').then(m => m.ContactComponent),
    data: {
      en: {
        title: 'Contact',
        breadcrumbs: [
          {
            name: 'Home',
            item: '/',
          },
          {
            name: 'Contact',
            item: 'contact',
          },
        ],
      },
      nl: {
        title: 'Contact',
        breadcrumbs: [
          {
            name: 'Startpagina',
            item: '/',
          },
          {
            name: 'Contact',
            item: 'contact',
          },
        ],
      },
    },
  },

  {
    path: 'signin',
    loadComponent: () => import('./pages/sign-in/sign-in.component').then(m => m.SignInComponent),
    canMatch: [userGuard],
    data: {
      en: {
        title: 'Sign in',
        breadcrumbs: [
          {
            name: 'Home',
            item: '/',
          },
          {
            name: 'Sign in',
            item: 'signin',
          },
        ],
      },
      nl: {
        title: 'Inloggen',
        breadcrumbs: [
          {
            name: 'Startpagina',
            item: '/',
          },
          {
            name: 'Inloggen',
            item: 'signin',
          },
        ],
      },
    },
  },
  {
    path: 'signout',
    loadComponent: () => import('./pages/sign-out/sign-out.component').then(m => m.SignOutComponent),
    data: {
      en: {
        title: 'Sign out',
        breadcrumbs: [
          {
            name: 'Home',
            item: '/',
          },
          {
            name: 'Sign out',
            item: 'signout',
          },
        ],
      },
      nl: {
        title: 'Uitloggen',
        breadcrumbs: [
          {
            name: 'Startpagina',
            item: '/',
          },
          {
            name: 'Uitloggen',
            item: 'signout',
          },
        ],
      },
    },
  },
  {
    path: 'reset-password',
    pathMatch: 'full',
    loadComponent: () => import('./pages/reset-password/reset-password.component').then(m => m.ResetPasswordComponent),
    canMatch: [userGuard],
    data: {
      en: {
        title: 'Reset password',
        breadcrumbs: [
          {
            name: 'Home',
            item: '/',
          },
          {
            name: 'Reset password',
            item: 'reset-password',
          },
        ],
      },
      nl: {
        title: 'Wachtwoord resetten',
        breadcrumbs: [
          {
            name: 'Startpagina',
            item: '/',
          },
          {
            name: 'Wachtwoord resetten',
            item: 'reset-password',
          },
        ],
      },
    },
  },
  {
    path: 'reset-password/:token',
    pathMatch: 'full',
    loadComponent: () => import('./pages/reset-password/new-password.component').then(m => m.NewPasswordComponent),
    canMatch: [userGuard],
    data: {
      en: {
        title: 'Reset password',
        breadcrumbs: [
          {
            name: 'Home',
            item: '/',
          },
          {
            name: 'Reset password',
            item: 'reset-password',
          },
        ],
      },
      nl: {
        title: 'Wachtwoord resetten',
        breadcrumbs: [
          {
            name: 'Startpagina',
            item: '/',
          },
          {
            name: 'Wachtwoord resetten',
            item: 'reset-password',
          },
        ],
      },
    },
  },

  {
    path: 'redirect',
    loadComponent: () => import('./pages/redirect/redirect.component').then(m => m.RedirectComponent),
    canMatch: [profileMatchGuard],
    data: {
      en: {
        title: 'Redirect',
        breadcrumbs: [
          {
            name: 'Home',
            item: '/',
          },
          {
            name: 'Redirect',
            item: 'redirect',
          },
        ],
      },
      nl: {
        title: 'Terugleiden',
        breadcrumbs: [
          {
            name: 'Startpagina',
            item: '/',
          },
          {
            name: 'Terugleiden',
            item: 'redirect',
          },
        ],
      },
    },
  },

  {
    path: 'calendar',
    loadComponent: () => import('./pages/calendar/calendar.component').then(m => m.CalendarComponent),
    canMatch: [profileMatchGuard],
    data: {
      en: {
        title: 'Calendar',
        breadcrumbs: [
          {
            name: 'Home',
            item: '/',
          },
          {
            name: 'Calendar',
            item: 'calendar',
          },
        ],
      },
      nl: {
        title: 'Agenda',
        breadcrumbs: [
          {
            name: 'Startpagina',
            item: '/',
          },
          {
            name: 'Agenda',
            item: 'calendar',
          },
        ],
      },
    },
  },

  {
    path: 'profile/settings',
    loadComponent: () => import('./pages/profile/settings/settings.component').then(m => m.ProfileSettingsComponent),
    canMatch: [profileMatchGuard],
    data: {
      en: {
        title: 'Profile - General',
        breadcrumbs: [
          {
            name: 'Home',
            item: '/',
          },
          {
            name: 'Profile',
          },
          {
            name: 'General',
            item: 'profile/settings',
          },
        ],
      },
      nl: {
        title: 'Profiel - Algemeen',
        breadcrumbs: [
          {
            name: 'Startpagina',
            item: '/',
          },
          {
            name: 'Profiel',
          },
          {
            name: 'Algemeen',
            item: 'profile/settings',
          },
        ],
      },
    },
  },
  {
    path: 'profile/security',
    loadComponent: () => import('./pages/profile/security/security.component').then(m => m.ProfileSecurityComponent),
    canMatch: [profileMatchGuard],
    data: {
      en: {
        title: 'Profile - Security',
        breadcrumbs: [
          {
            name: 'Home',
            item: '/',
          },
          {
            name: 'Profile',
          },
          {
            name: 'Security',
            item: 'profile/security',
          },
        ],
      },
      nl: {
        title: 'Profiel - Beveiliging',
        breadcrumbs: [
          {
            name: 'Startpagina',
            item: '/',
          },
          {
            name: 'Profiel',
          },
          {
            name: 'Beveiliging',
            item: 'profile/security',
          },
        ],
      },
    },
  },

  {
    path: 'admin/users',
    loadComponent: () => import('./pages/admin/users/users.component').then(m => m.AdminUsersComponent),
    canMatch: [adminMatchGuard],
    resolve: { users: adminUsersResolver },
    data: {
      en: {
        title: 'Admin - Users',
        breadcrumbs: [
          {
            name: 'Home',
            item: '/',
          },
          {
            name: 'Admin',
          },
          {
            name: 'Users',
            item: 'admin/users',
          },
        ],
      },
      nl: {
        title: 'Admin - Gebruikers',
        breadcrumbs: [
          {
            name: 'Startpagina',
            item: '/',
          },
          {
            name: 'Admin',
          },
          {
            name: 'Gebruikers',
            item: 'admin/users',
          },
        ],
      },
    },
  },
  {
    path: 'admin/users/new',
    loadComponent: () => import('./pages/admin/users/new-user.component').then(m => m.AdminNewUserComponent),
    canMatch: [adminMatchGuard],
    data: {
      en: {
        title: 'Admin - Add user',
        breadcrumbs: [
          {
            name: 'Home',
            item: '/',
          },
          {
            name: 'Admin',
          },
          {
            name: 'Users',
            item: 'admin/users',
          },
          {
            name: 'Add user',
            item: 'admin/users/add',
          },
        ],
      },
      nl: {
        title: 'Admin - gebruikers',
        breadcrumbs: [
          {
            name: 'Startpagina',
            item: '/',
          },
          {
            name: 'Admin',
          },
          {
            name: 'Gebruikers',
            item: 'admin/users',
          },
          {
            name: 'Nieuwe gebruiker',
            item: 'admin/users/add',
          },
        ],
      },
    },
  },

  {
    path: 'legal/privacy-policy',
    loadComponent: () => import('./pages/legal/privacy-policy.component').then(m => m.PrivacyPolicyComponent),
    data: {
      en: {
        title: 'Privacy Policy',
        breadcrumbs: [
          {
            name: 'Home',
            item: '/',
          },
          {
            name: 'Legal',
            item: 'legal',
          },
          {
            name: 'Privacy Policy',
            item: 'legal/privacy-policy',
          },
        ],
      },
      nl: {
        title: 'Privacybeleid',
        breadcrumbs: [
          {
            name: 'Startpagina',
            item: '/',
          },
          {
            name: 'Juridisch',
            item: 'legal',
          },
          {
            name: 'Privacybeleid',
            item: 'legal/privacy-policy',
          },
        ],
      },
    },
  },
  {
    path: 'legal/terms-of-service',
    loadComponent: () => import('./pages/legal/terms-of-service.component').then(m => m.TermsOfServiceComponent),
    data: {
      en: {
        title: 'Terms of Service',
        breadcrumbs: [
          {
            name: 'Home',
            item: '/',
          },
          {
            name: 'Legal',
            item: 'legal',
          },
          {
            name: 'Terms of Service',
            item: 'legal/terms-of-service',
          },
        ],
      },
      nl: {
        title: 'Algemene voorwaarden',
        breadcrumbs: [
          {
            name: 'Startpagina',
            item: '/',
          },
          {
            name: 'Juridisch',
            item: 'legal',
          },
          {
            name: 'Algemene voorwaarden',
            item: 'legal/terms-of-service',
          },
        ],
      },
    },
  },
  {
    path: 'legal/eula',
    loadComponent: () => import('./pages/legal/eula.component').then(m => m.EulaComponent),
    data: {
      en: {
        title: 'EULA',
        breadcrumbs: [
          {
            name: 'Home',
            item: '/',
          },
          {
            name: 'Legal',
            item: 'legal',
          },
          {
            name: 'EULA',
            item: 'legal/eula',
          },
        ],
      },
      nl: {
        title: 'EULA',
        breadcrumbs: [
          {
            name: 'Startpagina',
            item: '/',
          },
          {
            name: 'Juridisch',
            item: 'legal',
          },
          {
            name: 'EULA',
            item: 'legal/eula',
          },
        ],
      },
    },
  },
  {
    path: 'legal',
    pathMatch: 'full',
    loadComponent: () => import('./pages/legal/legal.component').then(m => m.LegalComponent),
    data: {
      en: {
        title: 'legal',
        breadcrumbs: [
          {
            name: 'Home',
            item: '/',
          },
          {
            name: 'Legal',
            item: 'legal',
          },
        ],
      },
      nl: {
        title: 'EULA',
        breadcrumbs: [
          {
            name: 'Startpagina',
            item: '/',
          },
          {
            name: 'Juridisch',
            item: 'legal',
          },
        ],
      },
    },
  },

  {
    path: '400',
    loadComponent: () => import('./pages/error/bad-request/bad-request.component').then(m => m.BadRequestComponent),
    data: {
      en: {
        title: 'Bad Request',
        breadcrumbs: [
          {
            name: 'Home',
            item: '/',
          },
          {
            name: 'Bad Request',
            item: '400',
          },
        ],
      },
      nl: {
        title: 'Foutieve aanvraag',
        breadcrumbs: [
          {
            name: 'Startpagina',
            item: '/',
          },
          {
            name: 'Foutieve aanvraag',
            item: '400',
          },
        ],
      },
    },
  },
  {
    path: '401',
    loadComponent: () => import('./pages/error/unauthorized/unauthorized.component').then(m => m.UnauthorizedComponent),
    data: {
      en: {
        title: 'Unauthorized',
        breadcrumbs: [
          {
            name: 'Home',
            item: '/',
          },
          {
            name: 'Unauthorized',
            item: '401',
          },
        ],
      },
      nl: {
        title: 'Ongeautoriseerd',
        breadcrumbs: [
          {
            name: 'Startpagina',
            item: '/',
          },
          {
            name: 'Ongeautoriseerd',
            item: '401',
          },
        ],
      },
    },
  },
  {
    path: '403',
    loadComponent: () => import('./pages/error/forbidden/forbidden.component').then(m => m.ForbiddenComponent),
    data: {
      en: {
        title: 'Forbidden',
        breadcrumbs: [
          {
            name: 'Home',
            item: '/',
          },
          {
            name: 'Forbidden',
            item: '403',
          },
        ],
      },
      nl: {
        title: 'Verboden toegang',
        breadcrumbs: [
          {
            name: 'Startpagina',
            item: '/',
          },
          {
            name: 'Verboden toegang',
            item: '403',
          },
        ],
      },
    },
  },
  {
    path: '404',
    loadComponent: () =>
      import('./pages/error/page-not-found/page-not-found.component').then(m => m.PageNotFoundComponent),
    data: {
      en: {
        title: 'Page not found',
        breadcrumbs: [
          {
            name: 'Home',
            item: '/',
          },
          {
            name: 'Page not found',
            item: '404',
          },
        ],
      },
      nl: {
        title: 'Pagina kon niet worden gevonden',
        breadcrumbs: [
          {
            name: 'Startpagina',
            item: '/',
          },
          {
            name: 'Pagina kon niet worden gevonden',
            item: '404',
          },
        ],
      },
    },
  },
  {
    path: '500',
    loadComponent: () => import('./pages/error/server-error/server-error.component').then(m => m.ServerErrorComponent),
    data: {
      en: {
        title: 'Server error',
        breadcrumbs: [
          {
            name: 'Home',
            item: '/',
          },
          {
            name: 'Server error',
            item: '500',
          },
        ],
      },
      nl: {
        title: 'Server error',
        breadcrumbs: [
          {
            name: 'Startpagina',
            item: '/',
          },
          {
            name: 'Server error',
            item: '500',
          },
        ],
      },
    },
  },

  // Cloudflare routes
  {
    path: 'challenge',
    loadComponent: () =>
      import('./components/app-challenge/app-challenge.component').then(m => m.AppChallengeComponent),
    data: {
      skipRouteLocalization: true,
      hideLayout: true,
    },
  },
  {
    path: 'captcha',
    loadComponent: () => import('./components/app-captcha/app-captcha.component').then(m => m.AppCaptchaComponent),
    data: {
      skipRouteLocalization: true,
      hideLayout: true,
    },
  },

  {
    path: 'shell',
    loadComponent: () => import('./components/app-shell/app-shell.component').then(m => m.AppShellComponent),
    data: {
      skipRouteLocalization: true,
    },
  },

  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/404',
  },
];
