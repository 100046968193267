import { ApplicationRef, inject, Injectable, InjectionToken, OnDestroy } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { filter, takeWhile, tap } from 'rxjs/operators';
import { forkJoin, Subscription } from 'rxjs';
import { injectNetwork } from 'ngxtension/inject-network';
import { WINDOW } from '@owain/tokens/window.provider';

const idleCallbackSupported = (window: Window | null): boolean =>
  typeof window !== 'undefined' ? !!(window as any).requestIdleCallback : false;

export const TRANSLOCO_PRELOAD_LANGUAGES = new InjectionToken<string[]>('Languages to be preloaded');

@Injectable({ providedIn: 'root' })
export class TranslocoPreloadLangsService implements OnDestroy {
  private readonly applicationRef: ApplicationRef = inject(ApplicationRef);
  private readonly translocoService: TranslocoService = inject(TranslocoService);
  private readonly window: Window = inject(WINDOW);
  private readonly network = injectNetwork();
  private readonly langs: string[] = inject(TRANSLOCO_PRELOAD_LANGUAGES);

  private readonly idleCallbackSupported: boolean = idleCallbackSupported(this.window);

  private idleCallbackId: number | undefined;
  private subscription: Subscription | null = null;
  private stableCount: number = 0;

  register() {
    if (!this.langs.length) {
      return;
    }

    if (this.network.supported()) {
      if (this.network.saveData() || (this.network.effectiveType() || '').includes('2g')) {
        return;
      }
    }

    if (!this.idleCallbackSupported) {
      return;
    }

    this.applicationRef.isStable
      .pipe(
        takeWhile(() => this.stableCount < 2),
        filter(isStable => isStable),
        tap(() => {
          this.stableCount++;

          if (this.stableCount < 2) {
            return;
          }

          this.idleCallbackId = this.window.requestIdleCallback(() => {
            const preloads = this.langs.map(currentLangOrScope => {
              const lang = this.translocoService._completeScopeWithLang(currentLangOrScope);

              return this.translocoService.load(lang).pipe(
                tap(() => {
                  if (!this.translocoService.config.prodMode) {
                    console.log(`%c 👁 Preloaded ${lang}`, 'background: #fff; color: #607D8B;');
                  }
                })
              );
            });
            this.subscription = forkJoin(preloads).subscribe();
          });
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    if (this.idleCallbackId !== undefined) {
      this.window.cancelIdleCallback(this.idleCallbackId);
    }

    this.subscription?.unsubscribe();
    // Caretaker note: it's important to clean up references to subscriptions since they save the `next`
    // callback within its `destination` property, preventing classes from being GC'd.
    this.subscription = null;
  }
}
